/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  json: any;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AuthOutput = {
  __typename?: 'AuthOutput';
  accessToken: Scalars['String'];
  exp: Scalars['Int'];
  userId: Scalars['uuid'];
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "KeyData" */
export type KeyData = {
  __typename?: 'KeyData';
  data: Scalars['jsonb'];
  key: Scalars['String'];
};

/** columns and relationships of "KeyData" */
export type KeyDataDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "KeyData" */
export type KeyDataAggregate = {
  __typename?: 'KeyData_aggregate';
  aggregate?: Maybe<KeyDataAggregateFields>;
  nodes: Array<KeyData>;
};

/** aggregate fields of "KeyData" */
export type KeyDataAggregateFields = {
  __typename?: 'KeyData_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<KeyDataMaxFields>;
  min?: Maybe<KeyDataMinFields>;
};

/** aggregate fields of "KeyData" */
export type KeyDataAggregateFieldsCountArgs = {
  columns?: Maybe<Array<KeyDataSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "KeyData" */
export type KeyDataAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<KeyDataMaxOrderBy>;
  min?: Maybe<KeyDataMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type KeyDataAppendInput = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "KeyData" */
export type KeyDataArrRelInsertInput = {
  data: Array<KeyDataInsertInput>;
  on_conflict?: Maybe<KeyDataOnConflict>;
};

/** Boolean expression to filter rows from the table "KeyData". All fields are combined with a logical 'AND'. */
export type KeyDataBoolExp = {
  _and?: Maybe<Array<Maybe<KeyDataBoolExp>>>;
  _not?: Maybe<KeyDataBoolExp>;
  _or?: Maybe<Array<Maybe<KeyDataBoolExp>>>;
  data?: Maybe<JsonbComparisonExp>;
  key?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "KeyData" */
export enum KeyDataConstraint {
  /** unique or primary key constraint */
  KeyDataPkey = 'KeyData_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type KeyDataDeleteAtPathInput = {
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type KeyDataDeleteElemInput = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type KeyDataDeleteKeyInput = {
  data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "KeyData" */
export type KeyDataInsertInput = {
  data?: Maybe<Scalars['jsonb']>;
  key?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type KeyDataMaxFields = {
  __typename?: 'KeyData_max_fields';
  key?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "KeyData" */
export type KeyDataMaxOrderBy = {
  key?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type KeyDataMinFields = {
  __typename?: 'KeyData_min_fields';
  key?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "KeyData" */
export type KeyDataMinOrderBy = {
  key?: Maybe<OrderBy>;
};

/** response of any mutation on the table "KeyData" */
export type KeyDataMutationResponse = {
  __typename?: 'KeyData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<KeyData>;
};

/** input type for inserting object relation for remote table "KeyData" */
export type KeyDataObjRelInsertInput = {
  data: KeyDataInsertInput;
  on_conflict?: Maybe<KeyDataOnConflict>;
};

/** on conflict condition type for table "KeyData" */
export type KeyDataOnConflict = {
  constraint: KeyDataConstraint;
  update_columns: Array<KeyDataUpdateColumn>;
  where?: Maybe<KeyDataBoolExp>;
};

/** ordering options when selecting data from "KeyData" */
export type KeyDataOrderBy = {
  data?: Maybe<OrderBy>;
  key?: Maybe<OrderBy>;
};

/** primary key columns input for table: "KeyData" */
export type KeyDataPkColumnsInput = {
  key: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type KeyDataPrependInput = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "KeyData" */
export enum KeyDataSelectColumn {
  /** column name */
  Data = 'data',
  /** column name */
  Key = 'key',
}

/** input type for updating data in table "KeyData" */
export type KeyDataSetInput = {
  data?: Maybe<Scalars['jsonb']>;
  key?: Maybe<Scalars['String']>;
};

/** update columns of table "KeyData" */
export enum KeyDataUpdateColumn {
  /** column name */
  Data = 'data',
  /** column name */
  Key = 'key',
}

/** columns and relationships of "Order" */
export type Order = {
  __typename?: 'Order';
  /** An object relationship */
  User?: Maybe<User>;
  code: Scalars['String'];
  id: Scalars['uuid'];
  status?: Maybe<OrderStatusEnum>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "OrderStatus" */
export type OrderStatus = {
  __typename?: 'OrderStatus';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "OrderStatus" */
export type OrderStatusAggregate = {
  __typename?: 'OrderStatus_aggregate';
  aggregate?: Maybe<OrderStatusAggregateFields>;
  nodes: Array<OrderStatus>;
};

/** aggregate fields of "OrderStatus" */
export type OrderStatusAggregateFields = {
  __typename?: 'OrderStatus_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<OrderStatusMaxFields>;
  min?: Maybe<OrderStatusMinFields>;
};

/** aggregate fields of "OrderStatus" */
export type OrderStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrderStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "OrderStatus" */
export type OrderStatusAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<OrderStatusMaxOrderBy>;
  min?: Maybe<OrderStatusMinOrderBy>;
};

/** input type for inserting array relation for remote table "OrderStatus" */
export type OrderStatusArrRelInsertInput = {
  data: Array<OrderStatusInsertInput>;
  on_conflict?: Maybe<OrderStatusOnConflict>;
};

/** Boolean expression to filter rows from the table "OrderStatus". All fields are combined with a logical 'AND'. */
export type OrderStatusBoolExp = {
  _and?: Maybe<Array<Maybe<OrderStatusBoolExp>>>;
  _not?: Maybe<OrderStatusBoolExp>;
  _or?: Maybe<Array<Maybe<OrderStatusBoolExp>>>;
  comment?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "OrderStatus" */
export enum OrderStatusConstraint {
  /** unique or primary key constraint */
  OrderStatusPkey = 'OrderStatus_pkey',
}

export enum OrderStatusEnum {
  Failed = 'FAILED',
}

/** expression to compare columns of type OrderStatus_enum. All fields are combined with logical 'AND'. */
export type OrderStatusEnumComparisonExp = {
  _eq?: Maybe<OrderStatusEnum>;
  _in?: Maybe<Array<OrderStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<OrderStatusEnum>;
  _nin?: Maybe<Array<OrderStatusEnum>>;
};

/** input type for inserting data into table "OrderStatus" */
export type OrderStatusInsertInput = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrderStatusMaxFields = {
  __typename?: 'OrderStatus_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "OrderStatus" */
export type OrderStatusMaxOrderBy = {
  comment?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OrderStatusMinFields = {
  __typename?: 'OrderStatus_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "OrderStatus" */
export type OrderStatusMinOrderBy = {
  comment?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** response of any mutation on the table "OrderStatus" */
export type OrderStatusMutationResponse = {
  __typename?: 'OrderStatus_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrderStatus>;
};

/** input type for inserting object relation for remote table "OrderStatus" */
export type OrderStatusObjRelInsertInput = {
  data: OrderStatusInsertInput;
  on_conflict?: Maybe<OrderStatusOnConflict>;
};

/** on conflict condition type for table "OrderStatus" */
export type OrderStatusOnConflict = {
  constraint: OrderStatusConstraint;
  update_columns: Array<OrderStatusUpdateColumn>;
  where?: Maybe<OrderStatusBoolExp>;
};

/** ordering options when selecting data from "OrderStatus" */
export type OrderStatusOrderBy = {
  comment?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: "OrderStatus" */
export type OrderStatusPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "OrderStatus" */
export enum OrderStatusSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "OrderStatus" */
export type OrderStatusSetInput = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "OrderStatus" */
export enum OrderStatusUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** aggregated selection of "Order" */
export type OrderAggregate = {
  __typename?: 'Order_aggregate';
  aggregate?: Maybe<OrderAggregateFields>;
  nodes: Array<Order>;
};

/** aggregate fields of "Order" */
export type OrderAggregateFields = {
  __typename?: 'Order_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<OrderMaxFields>;
  min?: Maybe<OrderMinFields>;
};

/** aggregate fields of "Order" */
export type OrderAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrderSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Order" */
export type OrderAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<OrderMaxOrderBy>;
  min?: Maybe<OrderMinOrderBy>;
};

/** input type for inserting array relation for remote table "Order" */
export type OrderArrRelInsertInput = {
  data: Array<OrderInsertInput>;
  on_conflict?: Maybe<OrderOnConflict>;
};

/** Boolean expression to filter rows from the table "Order". All fields are combined with a logical 'AND'. */
export type OrderBoolExp = {
  User?: Maybe<UserBoolExp>;
  _and?: Maybe<Array<Maybe<OrderBoolExp>>>;
  _not?: Maybe<OrderBoolExp>;
  _or?: Maybe<Array<Maybe<OrderBoolExp>>>;
  code?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  status?: Maybe<OrderStatusEnumComparisonExp>;
  userId?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "Order" */
export enum OrderConstraint {
  /** unique or primary key constraint */
  OrderCodeKey = 'Order_code_key',
  /** unique or primary key constraint */
  OrderPkey = 'Order_pkey',
}

/** input type for inserting data into table "Order" */
export type OrderInsertInput = {
  User?: Maybe<UserObjRelInsertInput>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<OrderStatusEnum>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type OrderMaxFields = {
  __typename?: 'Order_max_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Order" */
export type OrderMaxOrderBy = {
  code?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OrderMinFields = {
  __typename?: 'Order_min_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Order" */
export type OrderMinOrderBy = {
  code?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "Order" */
export type OrderMutationResponse = {
  __typename?: 'Order_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Order>;
};

/** input type for inserting object relation for remote table "Order" */
export type OrderObjRelInsertInput = {
  data: OrderInsertInput;
  on_conflict?: Maybe<OrderOnConflict>;
};

/** on conflict condition type for table "Order" */
export type OrderOnConflict = {
  constraint: OrderConstraint;
  update_columns: Array<OrderUpdateColumn>;
  where?: Maybe<OrderBoolExp>;
};

/** ordering options when selecting data from "Order" */
export type OrderOrderBy = {
  User?: Maybe<UserOrderBy>;
  code?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** primary key columns input for table: "Order" */
export type OrderPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "Order" */
export enum OrderSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "Order" */
export type OrderSetInput = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<OrderStatusEnum>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "Order" */
export enum OrderUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId',
}

/** columns and relationships of "PaymentStatus" */
export type PaymentStatus = {
  __typename?: 'PaymentStatus';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "PaymentStatus" */
export type PaymentStatusAggregate = {
  __typename?: 'PaymentStatus_aggregate';
  aggregate?: Maybe<PaymentStatusAggregateFields>;
  nodes: Array<PaymentStatus>;
};

/** aggregate fields of "PaymentStatus" */
export type PaymentStatusAggregateFields = {
  __typename?: 'PaymentStatus_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<PaymentStatusMaxFields>;
  min?: Maybe<PaymentStatusMinFields>;
};

/** aggregate fields of "PaymentStatus" */
export type PaymentStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PaymentStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PaymentStatus" */
export type PaymentStatusAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<PaymentStatusMaxOrderBy>;
  min?: Maybe<PaymentStatusMinOrderBy>;
};

/** input type for inserting array relation for remote table "PaymentStatus" */
export type PaymentStatusArrRelInsertInput = {
  data: Array<PaymentStatusInsertInput>;
  on_conflict?: Maybe<PaymentStatusOnConflict>;
};

/** Boolean expression to filter rows from the table "PaymentStatus". All fields are combined with a logical 'AND'. */
export type PaymentStatusBoolExp = {
  _and?: Maybe<Array<Maybe<PaymentStatusBoolExp>>>;
  _not?: Maybe<PaymentStatusBoolExp>;
  _or?: Maybe<Array<Maybe<PaymentStatusBoolExp>>>;
  comment?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "PaymentStatus" */
export enum PaymentStatusConstraint {
  /** unique or primary key constraint */
  PaymentStatusPkey = 'PaymentStatus_pkey',
}

/** input type for inserting data into table "PaymentStatus" */
export type PaymentStatusInsertInput = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PaymentStatusMaxFields = {
  __typename?: 'PaymentStatus_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "PaymentStatus" */
export type PaymentStatusMaxOrderBy = {
  comment?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type PaymentStatusMinFields = {
  __typename?: 'PaymentStatus_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "PaymentStatus" */
export type PaymentStatusMinOrderBy = {
  comment?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** response of any mutation on the table "PaymentStatus" */
export type PaymentStatusMutationResponse = {
  __typename?: 'PaymentStatus_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<PaymentStatus>;
};

/** input type for inserting object relation for remote table "PaymentStatus" */
export type PaymentStatusObjRelInsertInput = {
  data: PaymentStatusInsertInput;
  on_conflict?: Maybe<PaymentStatusOnConflict>;
};

/** on conflict condition type for table "PaymentStatus" */
export type PaymentStatusOnConflict = {
  constraint: PaymentStatusConstraint;
  update_columns: Array<PaymentStatusUpdateColumn>;
  where?: Maybe<PaymentStatusBoolExp>;
};

/** ordering options when selecting data from "PaymentStatus" */
export type PaymentStatusOrderBy = {
  comment?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: "PaymentStatus" */
export type PaymentStatusPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "PaymentStatus" */
export enum PaymentStatusSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "PaymentStatus" */
export type PaymentStatusSetInput = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "PaymentStatus" */
export enum PaymentStatusUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** columns and relationships of "Product" */
export type Product = {
  __typename?: 'Product';
  /** fill manually */
  code: Scalars['String'];
  createdOn: Scalars['timestamptz'];
  id: Scalars['uuid'];
  meta?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  photos?: Maybe<Scalars['jsonb']>;
  spec?: Maybe<Scalars['jsonb']>;
  updatedOn: Scalars['timestamptz'];
};

/** columns and relationships of "Product" */
export type ProductMetaArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Product" */
export type ProductPhotosArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Product" */
export type ProductSpecArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "ProductCustomized" */
export type ProductCustomized = {
  __typename?: 'ProductCustomized';
  /** An object relationship */
  Product: Product;
  /** An object relationship */
  User: User;
  code: Scalars['String'];
  createdOn: Scalars['timestamp'];
  id: Scalars['uuid'];
  productId: Scalars['uuid'];
  spec?: Maybe<Scalars['jsonb']>;
  updatedOn: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** columns and relationships of "ProductCustomized" */
export type ProductCustomizedSpecArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ProductCustomized" */
export type ProductCustomizedAggregate = {
  __typename?: 'ProductCustomized_aggregate';
  aggregate?: Maybe<ProductCustomizedAggregateFields>;
  nodes: Array<ProductCustomized>;
};

/** aggregate fields of "ProductCustomized" */
export type ProductCustomizedAggregateFields = {
  __typename?: 'ProductCustomized_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductCustomizedMaxFields>;
  min?: Maybe<ProductCustomizedMinFields>;
};

/** aggregate fields of "ProductCustomized" */
export type ProductCustomizedAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProductCustomizedSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ProductCustomized" */
export type ProductCustomizedAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ProductCustomizedMaxOrderBy>;
  min?: Maybe<ProductCustomizedMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ProductCustomizedAppendInput = {
  spec?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "ProductCustomized" */
export type ProductCustomizedArrRelInsertInput = {
  data: Array<ProductCustomizedInsertInput>;
  on_conflict?: Maybe<ProductCustomizedOnConflict>;
};

/** Boolean expression to filter rows from the table "ProductCustomized". All fields are combined with a logical 'AND'. */
export type ProductCustomizedBoolExp = {
  Product?: Maybe<ProductBoolExp>;
  User?: Maybe<UserBoolExp>;
  _and?: Maybe<Array<Maybe<ProductCustomizedBoolExp>>>;
  _not?: Maybe<ProductCustomizedBoolExp>;
  _or?: Maybe<Array<Maybe<ProductCustomizedBoolExp>>>;
  code?: Maybe<StringComparisonExp>;
  createdOn?: Maybe<TimestampComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  productId?: Maybe<UuidComparisonExp>;
  spec?: Maybe<JsonbComparisonExp>;
  updatedOn?: Maybe<TimestamptzComparisonExp>;
  userId?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ProductCustomized" */
export enum ProductCustomizedConstraint {
  /** unique or primary key constraint */
  ProductCustomizedCodeKey = 'ProductCustomized_code_key',
  /** unique or primary key constraint */
  ProductCustomizedPkey = 'ProductCustomized_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ProductCustomizedDeleteAtPathInput = {
  spec?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ProductCustomizedDeleteElemInput = {
  spec?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ProductCustomizedDeleteKeyInput = {
  spec?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "ProductCustomized" */
export type ProductCustomizedInsertInput = {
  Product?: Maybe<ProductObjRelInsertInput>;
  User?: Maybe<UserObjRelInsertInput>;
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  spec?: Maybe<Scalars['jsonb']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProductCustomizedMaxFields = {
  __typename?: 'ProductCustomized_max_fields';
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ProductCustomized" */
export type ProductCustomizedMaxOrderBy = {
  code?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  productId?: Maybe<OrderBy>;
  updatedOn?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProductCustomizedMinFields = {
  __typename?: 'ProductCustomized_min_fields';
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ProductCustomized" */
export type ProductCustomizedMinOrderBy = {
  code?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  productId?: Maybe<OrderBy>;
  updatedOn?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ProductCustomized" */
export type ProductCustomizedMutationResponse = {
  __typename?: 'ProductCustomized_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ProductCustomized>;
};

/** input type for inserting object relation for remote table "ProductCustomized" */
export type ProductCustomizedObjRelInsertInput = {
  data: ProductCustomizedInsertInput;
  on_conflict?: Maybe<ProductCustomizedOnConflict>;
};

/** on conflict condition type for table "ProductCustomized" */
export type ProductCustomizedOnConflict = {
  constraint: ProductCustomizedConstraint;
  update_columns: Array<ProductCustomizedUpdateColumn>;
  where?: Maybe<ProductCustomizedBoolExp>;
};

/** ordering options when selecting data from "ProductCustomized" */
export type ProductCustomizedOrderBy = {
  Product?: Maybe<ProductOrderBy>;
  User?: Maybe<UserOrderBy>;
  code?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  productId?: Maybe<OrderBy>;
  spec?: Maybe<OrderBy>;
  updatedOn?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** primary key columns input for table: "ProductCustomized" */
export type ProductCustomizedPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ProductCustomizedPrependInput = {
  spec?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "ProductCustomized" */
export enum ProductCustomizedSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedOn = 'createdOn',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Spec = 'spec',
  /** column name */
  UpdatedOn = 'updatedOn',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "ProductCustomized" */
export type ProductCustomizedSetInput = {
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  spec?: Maybe<Scalars['jsonb']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "ProductCustomized" */
export enum ProductCustomizedUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedOn = 'createdOn',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'productId',
  /** column name */
  Spec = 'spec',
  /** column name */
  UpdatedOn = 'updatedOn',
  /** column name */
  UserId = 'userId',
}

/** aggregated selection of "Product" */
export type ProductAggregate = {
  __typename?: 'Product_aggregate';
  aggregate?: Maybe<ProductAggregateFields>;
  nodes: Array<Product>;
};

/** aggregate fields of "Product" */
export type ProductAggregateFields = {
  __typename?: 'Product_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductMaxFields>;
  min?: Maybe<ProductMinFields>;
};

/** aggregate fields of "Product" */
export type ProductAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProductSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Product" */
export type ProductAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ProductMaxOrderBy>;
  min?: Maybe<ProductMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ProductAppendInput = {
  meta?: Maybe<Scalars['jsonb']>;
  photos?: Maybe<Scalars['jsonb']>;
  spec?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Product" */
export type ProductArrRelInsertInput = {
  data: Array<ProductInsertInput>;
  on_conflict?: Maybe<ProductOnConflict>;
};

/** Boolean expression to filter rows from the table "Product". All fields are combined with a logical 'AND'. */
export type ProductBoolExp = {
  _and?: Maybe<Array<Maybe<ProductBoolExp>>>;
  _not?: Maybe<ProductBoolExp>;
  _or?: Maybe<Array<Maybe<ProductBoolExp>>>;
  code?: Maybe<StringComparisonExp>;
  createdOn?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  meta?: Maybe<JsonbComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  photos?: Maybe<JsonbComparisonExp>;
  spec?: Maybe<JsonbComparisonExp>;
  updatedOn?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "Product" */
export enum ProductConstraint {
  /** unique or primary key constraint */
  ProductPkey = 'Product_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ProductDeleteAtPathInput = {
  meta?: Maybe<Array<Maybe<Scalars['String']>>>;
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  spec?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ProductDeleteElemInput = {
  meta?: Maybe<Scalars['Int']>;
  photos?: Maybe<Scalars['Int']>;
  spec?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ProductDeleteKeyInput = {
  meta?: Maybe<Scalars['String']>;
  photos?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "Product" */
export type ProductInsertInput = {
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meta?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  photos?: Maybe<Scalars['jsonb']>;
  spec?: Maybe<Scalars['jsonb']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProductMaxFields = {
  __typename?: 'Product_max_fields';
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Product" */
export type ProductMaxOrderBy = {
  code?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updatedOn?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProductMinFields = {
  __typename?: 'Product_min_fields';
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Product" */
export type ProductMinOrderBy = {
  code?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updatedOn?: Maybe<OrderBy>;
};

/** response of any mutation on the table "Product" */
export type ProductMutationResponse = {
  __typename?: 'Product_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "Product" */
export type ProductObjRelInsertInput = {
  data: ProductInsertInput;
  on_conflict?: Maybe<ProductOnConflict>;
};

/** on conflict condition type for table "Product" */
export type ProductOnConflict = {
  constraint: ProductConstraint;
  update_columns: Array<ProductUpdateColumn>;
  where?: Maybe<ProductBoolExp>;
};

/** ordering options when selecting data from "Product" */
export type ProductOrderBy = {
  code?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  meta?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  photos?: Maybe<OrderBy>;
  spec?: Maybe<OrderBy>;
  updatedOn?: Maybe<OrderBy>;
};

/** primary key columns input for table: "Product" */
export type ProductPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ProductPrependInput = {
  meta?: Maybe<Scalars['jsonb']>;
  photos?: Maybe<Scalars['jsonb']>;
  spec?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Product" */
export enum ProductSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedOn = 'createdOn',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Photos = 'photos',
  /** column name */
  Spec = 'spec',
  /** column name */
  UpdatedOn = 'updatedOn',
}

/** input type for updating data in table "Product" */
export type ProductSetInput = {
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meta?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  photos?: Maybe<Scalars['jsonb']>;
  spec?: Maybe<Scalars['jsonb']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "Product" */
export enum ProductUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedOn = 'createdOn',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Photos = 'photos',
  /** column name */
  Spec = 'spec',
  /** column name */
  UpdatedOn = 'updatedOn',
}

export type SignInInput = {
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SignUpInput = {
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum SocialProvider {
  Facebook = 'Facebook',
}

export type SocialSignInInput = {
  accessToken: Scalars['String'];
  provider: SocialProvider;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type UpdatePasswordInput = {
  currentPassword?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  sessionUserId?: Maybe<Scalars['String']>;
};

export type UpdatePasswordOutput = {
  __typename?: 'UpdatePasswordOutput';
  success: Scalars['Boolean'];
};

/** columns and relationships of "User" */
export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['jsonb']>;
  createdOn: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isEmailVerified: Scalars['Boolean'];
  isPhoneNumberVerified: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedOn: Scalars['timestamptz'];
  role: Maybe<Scalars['String']>;
};

/** columns and relationships of "User" */
export type UserAvatarArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "User" */
export type UserAggregate = {
  __typename?: 'User_aggregate';
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
};

/** aggregate fields of "User" */
export type UserAggregateFields = {
  __typename?: 'User_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
};

/** aggregate fields of "User" */
export type UserAggregateFieldsCountArgs = {
  columns?: Maybe<Array<UserSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "User" */
export type UserAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<UserMaxOrderBy>;
  min?: Maybe<UserMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserAppendInput = {
  avatar?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "User" */
export type UserArrRelInsertInput = {
  data: Array<UserInsertInput>;
  on_conflict?: Maybe<UserOnConflict>;
};

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and?: Maybe<Array<Maybe<UserBoolExp>>>;
  _not?: Maybe<UserBoolExp>;
  _or?: Maybe<Array<Maybe<UserBoolExp>>>;
  avatar?: Maybe<JsonbComparisonExp>;
  createdOn?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  isEmailVerified?: Maybe<BooleanComparisonExp>;
  isPhoneNumberVerified?: Maybe<BooleanComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  password?: Maybe<StringComparisonExp>;
  phoneNumber?: Maybe<StringComparisonExp>;
  updatedOn?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "User" */
export enum UserConstraint {
  /** unique or primary key constraint */
  UserEmailKey = 'User_email_key',
  /** unique or primary key constraint */
  UserPhoneNumberKey = 'User_phoneNumber_key',
  /** unique or primary key constraint */
  UserPkey = 'User_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserDeleteAtPathInput = {
  avatar?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserDeleteElemInput = {
  avatar?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserDeleteKeyInput = {
  avatar?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "User" */
export type UserInsertInput = {
  avatar?: Maybe<Scalars['jsonb']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isPhoneNumberVerified?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type UserMaxFields = {
  __typename?: 'User_max_fields';
  createdOn?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "User" */
export type UserMaxOrderBy = {
  createdOn?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  phoneNumber?: Maybe<OrderBy>;
  updatedOn?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type UserMinFields = {
  __typename?: 'User_min_fields';
  createdOn?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "User" */
export type UserMinOrderBy = {
  createdOn?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  phoneNumber?: Maybe<OrderBy>;
  updatedOn?: Maybe<OrderBy>;
};

/** response of any mutation on the table "User" */
export type UserMutationResponse = {
  __typename?: 'User_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "User" */
export type UserObjRelInsertInput = {
  data: UserInsertInput;
  on_conflict?: Maybe<UserOnConflict>;
};

/** on conflict condition type for table "User" */
export type UserOnConflict = {
  constraint: UserConstraint;
  update_columns: Array<UserUpdateColumn>;
  where?: Maybe<UserBoolExp>;
};

/** ordering options when selecting data from "User" */
export type UserOrderBy = {
  avatar?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isEmailVerified?: Maybe<OrderBy>;
  isPhoneNumberVerified?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  phoneNumber?: Maybe<OrderBy>;
  updatedOn?: Maybe<OrderBy>;
};

/** primary key columns input for table: "User" */
export type UserPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserPrependInput = {
  avatar?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "User" */
export enum UserSelectColumn {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedOn = 'createdOn',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsEmailVerified = 'isEmailVerified',
  /** column name */
  IsPhoneNumberVerified = 'isPhoneNumberVerified',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  UpdatedOn = 'updatedOn',
}

/** input type for updating data in table "User" */
export type UserSetInput = {
  avatar?: Maybe<Scalars['jsonb']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isPhoneNumberVerified?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "User" */
export enum UserUpdateColumn {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedOn = 'createdOn',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsEmailVerified = 'isEmailVerified',
  /** column name */
  IsPhoneNumberVerified = 'isPhoneNumberVerified',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  UpdatedOn = 'updatedOn',
}

/** columns and relationships of "VerifyCode" */
export type VerifyCode = {
  __typename?: 'VerifyCode';
  code: Scalars['String'];
  createdOn: Scalars['timestamptz'];
  id: Scalars['uuid'];
  verifyEmail?: Maybe<Scalars['String']>;
  verifyPhoneNumber?: Maybe<Scalars['String']>;
};

export enum VerifyCodeTemplate {
  Code = 'CODE',
  ForgotPassword = 'FORGOT_PASSWORD',
  UpdateEmail = 'UPDATE_EMAIL',
  Welcome = 'WELCOME',
}

/** aggregated selection of "VerifyCode" */
export type VerifyCodeAggregate = {
  __typename?: 'VerifyCode_aggregate';
  aggregate?: Maybe<VerifyCodeAggregateFields>;
  nodes: Array<VerifyCode>;
};

/** aggregate fields of "VerifyCode" */
export type VerifyCodeAggregateFields = {
  __typename?: 'VerifyCode_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<VerifyCodeMaxFields>;
  min?: Maybe<VerifyCodeMinFields>;
};

/** aggregate fields of "VerifyCode" */
export type VerifyCodeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<VerifyCodeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "VerifyCode" */
export type VerifyCodeAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<VerifyCodeMaxOrderBy>;
  min?: Maybe<VerifyCodeMinOrderBy>;
};

/** input type for inserting array relation for remote table "VerifyCode" */
export type VerifyCodeArrRelInsertInput = {
  data: Array<VerifyCodeInsertInput>;
  on_conflict?: Maybe<VerifyCodeOnConflict>;
};

/** Boolean expression to filter rows from the table "VerifyCode". All fields are combined with a logical 'AND'. */
export type VerifyCodeBoolExp = {
  _and?: Maybe<Array<Maybe<VerifyCodeBoolExp>>>;
  _not?: Maybe<VerifyCodeBoolExp>;
  _or?: Maybe<Array<Maybe<VerifyCodeBoolExp>>>;
  code?: Maybe<StringComparisonExp>;
  createdOn?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  verifyEmail?: Maybe<StringComparisonExp>;
  verifyPhoneNumber?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "VerifyCode" */
export enum VerifyCodeConstraint {
  /** unique or primary key constraint */
  VerifyCodePkey = 'VerifyCode_pkey',
}

/** input type for inserting data into table "VerifyCode" */
export type VerifyCodeInsertInput = {
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  verifyEmail?: Maybe<Scalars['String']>;
  verifyPhoneNumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type VerifyCodeMaxFields = {
  __typename?: 'VerifyCode_max_fields';
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  verifyEmail?: Maybe<Scalars['String']>;
  verifyPhoneNumber?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "VerifyCode" */
export type VerifyCodeMaxOrderBy = {
  code?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  verifyEmail?: Maybe<OrderBy>;
  verifyPhoneNumber?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type VerifyCodeMinFields = {
  __typename?: 'VerifyCode_min_fields';
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  verifyEmail?: Maybe<Scalars['String']>;
  verifyPhoneNumber?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "VerifyCode" */
export type VerifyCodeMinOrderBy = {
  code?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  verifyEmail?: Maybe<OrderBy>;
  verifyPhoneNumber?: Maybe<OrderBy>;
};

/** response of any mutation on the table "VerifyCode" */
export type VerifyCodeMutationResponse = {
  __typename?: 'VerifyCode_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<VerifyCode>;
};

/** input type for inserting object relation for remote table "VerifyCode" */
export type VerifyCodeObjRelInsertInput = {
  data: VerifyCodeInsertInput;
  on_conflict?: Maybe<VerifyCodeOnConflict>;
};

/** on conflict condition type for table "VerifyCode" */
export type VerifyCodeOnConflict = {
  constraint: VerifyCodeConstraint;
  update_columns: Array<VerifyCodeUpdateColumn>;
  where?: Maybe<VerifyCodeBoolExp>;
};

/** ordering options when selecting data from "VerifyCode" */
export type VerifyCodeOrderBy = {
  code?: Maybe<OrderBy>;
  createdOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  verifyEmail?: Maybe<OrderBy>;
  verifyPhoneNumber?: Maybe<OrderBy>;
};

/** primary key columns input for table: "VerifyCode" */
export type VerifyCodePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "VerifyCode" */
export enum VerifyCodeSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedOn = 'createdOn',
  /** column name */
  Id = 'id',
  /** column name */
  VerifyEmail = 'verifyEmail',
  /** column name */
  VerifyPhoneNumber = 'verifyPhoneNumber',
}

/** input type for updating data in table "VerifyCode" */
export type VerifyCodeSetInput = {
  code?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  verifyEmail?: Maybe<Scalars['String']>;
  verifyPhoneNumber?: Maybe<Scalars['String']>;
};

/** update columns of table "VerifyCode" */
export enum VerifyCodeUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedOn = 'createdOn',
  /** column name */
  Id = 'id',
  /** column name */
  VerifyEmail = 'verifyEmail',
  /** column name */
  VerifyPhoneNumber = 'verifyPhoneNumber',
}

/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type JsonComparisonExp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};

/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** delete data from the table: "KeyData" */
  delete_KeyData?: Maybe<KeyDataMutationResponse>;
  /** delete single row from the table: "KeyData" */
  delete_KeyData_by_pk?: Maybe<KeyData>;
  /** delete data from the table: "Order" */
  delete_Order?: Maybe<OrderMutationResponse>;
  /** delete data from the table: "OrderStatus" */
  delete_OrderStatus?: Maybe<OrderStatusMutationResponse>;
  /** delete single row from the table: "OrderStatus" */
  delete_OrderStatus_by_pk?: Maybe<OrderStatus>;
  /** delete single row from the table: "Order" */
  delete_Order_by_pk?: Maybe<Order>;
  /** delete data from the table: "PaymentStatus" */
  delete_PaymentStatus?: Maybe<PaymentStatusMutationResponse>;
  /** delete single row from the table: "PaymentStatus" */
  delete_PaymentStatus_by_pk?: Maybe<PaymentStatus>;
  /** delete data from the table: "Product" */
  delete_Product?: Maybe<ProductMutationResponse>;
  /** delete data from the table: "ProductCustomized" */
  delete_ProductCustomized?: Maybe<ProductCustomizedMutationResponse>;
  /** delete single row from the table: "ProductCustomized" */
  delete_ProductCustomized_by_pk?: Maybe<ProductCustomized>;
  /** delete single row from the table: "Product" */
  delete_Product_by_pk?: Maybe<Product>;
  /** delete data from the table: "User" */
  delete_User?: Maybe<UserMutationResponse>;
  /** delete single row from the table: "User" */
  delete_User_by_pk?: Maybe<User>;
  /** delete data from the table: "VerifyCode" */
  delete_VerifyCode?: Maybe<VerifyCodeMutationResponse>;
  /** delete single row from the table: "VerifyCode" */
  delete_VerifyCode_by_pk?: Maybe<VerifyCode>;
  /** insert data into the table: "KeyData" */
  insert_KeyData?: Maybe<KeyDataMutationResponse>;
  /** insert a single row into the table: "KeyData" */
  insert_KeyData_one?: Maybe<KeyData>;
  /** insert data into the table: "Order" */
  insert_Order?: Maybe<OrderMutationResponse>;
  /** insert data into the table: "OrderStatus" */
  insert_OrderStatus?: Maybe<OrderStatusMutationResponse>;
  /** insert a single row into the table: "OrderStatus" */
  insert_OrderStatus_one?: Maybe<OrderStatus>;
  /** insert a single row into the table: "Order" */
  insert_Order_one?: Maybe<Order>;
  /** insert data into the table: "PaymentStatus" */
  insert_PaymentStatus?: Maybe<PaymentStatusMutationResponse>;
  /** insert a single row into the table: "PaymentStatus" */
  insert_PaymentStatus_one?: Maybe<PaymentStatus>;
  /** insert data into the table: "Product" */
  insert_Product?: Maybe<ProductMutationResponse>;
  /** insert data into the table: "ProductCustomized" */
  insert_ProductCustomized?: Maybe<ProductCustomizedMutationResponse>;
  /** insert a single row into the table: "ProductCustomized" */
  insert_ProductCustomized_one?: Maybe<ProductCustomized>;
  /** insert a single row into the table: "Product" */
  insert_Product_one?: Maybe<Product>;
  /** insert data into the table: "User" */
  insert_User?: Maybe<UserMutationResponse>;
  /** insert a single row into the table: "User" */
  insert_User_one?: Maybe<User>;
  /** insert data into the table: "VerifyCode" */
  insert_VerifyCode?: Maybe<VerifyCodeMutationResponse>;
  /** insert a single row into the table: "VerifyCode" */
  insert_VerifyCode_one?: Maybe<VerifyCode>;
  /** perform the action: "sendVerifyCode" */
  sendVerifyCode?: Maybe<SendVerifyCodeOutput>;
  /** perform the action: "signIn" */
  signIn?: Maybe<AuthOutput>;
  /** perform the action: "signUp" */
  signUp?: Maybe<AuthOutput>;
  /** perform the action: "socialSignIn" */
  socialSignIn?: Maybe<AuthOutput>;
  /** perform the action: "updatePassword" */
  updatePassword?: Maybe<UpdatePasswordOutput>;
  /** perform the action: "updatePhoneNumber" */
  updatePhoneNumber?: Maybe<UpdatePhoneNumberOutput>;
  /** update data of the table: "KeyData" */
  update_KeyData?: Maybe<KeyDataMutationResponse>;
  /** update single row of the table: "KeyData" */
  update_KeyData_by_pk?: Maybe<KeyData>;
  /** update data of the table: "Order" */
  update_Order?: Maybe<OrderMutationResponse>;
  /** update data of the table: "OrderStatus" */
  update_OrderStatus?: Maybe<OrderStatusMutationResponse>;
  /** update single row of the table: "OrderStatus" */
  update_OrderStatus_by_pk?: Maybe<OrderStatus>;
  /** update single row of the table: "Order" */
  update_Order_by_pk?: Maybe<Order>;
  /** update data of the table: "PaymentStatus" */
  update_PaymentStatus?: Maybe<PaymentStatusMutationResponse>;
  /** update single row of the table: "PaymentStatus" */
  update_PaymentStatus_by_pk?: Maybe<PaymentStatus>;
  /** update data of the table: "Product" */
  update_Product?: Maybe<ProductMutationResponse>;
  /** update data of the table: "ProductCustomized" */
  update_ProductCustomized?: Maybe<ProductCustomizedMutationResponse>;
  /** update single row of the table: "ProductCustomized" */
  update_ProductCustomized_by_pk?: Maybe<ProductCustomized>;
  /** update single row of the table: "Product" */
  update_Product_by_pk?: Maybe<Product>;
  /** update data of the table: "User" */
  update_User?: Maybe<UserMutationResponse>;
  /** update single row of the table: "User" */
  update_User_by_pk?: Maybe<User>;
  /** update data of the table: "VerifyCode" */
  update_VerifyCode?: Maybe<VerifyCodeMutationResponse>;
  /** update single row of the table: "VerifyCode" */
  update_VerifyCode_by_pk?: Maybe<VerifyCode>;
};

/** mutation root */
export type MutationRootDeleteKeyDataArgs = {
  where: KeyDataBoolExp;
};

/** mutation root */
export type MutationRootDeleteKeyDataByPkArgs = {
  key: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteOrderArgs = {
  where: OrderBoolExp;
};

/** mutation root */
export type MutationRootDeleteOrderStatusArgs = {
  where: OrderStatusBoolExp;
};

/** mutation root */
export type MutationRootDeleteOrderStatusByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteOrderByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeletePaymentStatusArgs = {
  where: PaymentStatusBoolExp;
};

/** mutation root */
export type MutationRootDeletePaymentStatusByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteProductArgs = {
  where: ProductBoolExp;
};

/** mutation root */
export type MutationRootDeleteProductCustomizedArgs = {
  where: ProductCustomizedBoolExp;
};

/** mutation root */
export type MutationRootDeleteProductCustomizedByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteProductByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteUserArgs = {
  where: UserBoolExp;
};

/** mutation root */
export type MutationRootDeleteUserByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteVerifyCodeArgs = {
  where: VerifyCodeBoolExp;
};

/** mutation root */
export type MutationRootDeleteVerifyCodeByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootInsertKeyDataArgs = {
  objects: Array<KeyDataInsertInput>;
  on_conflict?: Maybe<KeyDataOnConflict>;
};

/** mutation root */
export type MutationRootInsertKeyDataOneArgs = {
  object: KeyDataInsertInput;
  on_conflict?: Maybe<KeyDataOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrderArgs = {
  objects: Array<OrderInsertInput>;
  on_conflict?: Maybe<OrderOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrderStatusArgs = {
  objects: Array<OrderStatusInsertInput>;
  on_conflict?: Maybe<OrderStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrderStatusOneArgs = {
  object: OrderStatusInsertInput;
  on_conflict?: Maybe<OrderStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrderOneArgs = {
  object: OrderInsertInput;
  on_conflict?: Maybe<OrderOnConflict>;
};

/** mutation root */
export type MutationRootInsertPaymentStatusArgs = {
  objects: Array<PaymentStatusInsertInput>;
  on_conflict?: Maybe<PaymentStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertPaymentStatusOneArgs = {
  object: PaymentStatusInsertInput;
  on_conflict?: Maybe<PaymentStatusOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductArgs = {
  objects: Array<ProductInsertInput>;
  on_conflict?: Maybe<ProductOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductCustomizedArgs = {
  objects: Array<ProductCustomizedInsertInput>;
  on_conflict?: Maybe<ProductCustomizedOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductCustomizedOneArgs = {
  object: ProductCustomizedInsertInput;
  on_conflict?: Maybe<ProductCustomizedOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductOneArgs = {
  object: ProductInsertInput;
  on_conflict?: Maybe<ProductOnConflict>;
};

/** mutation root */
export type MutationRootInsertUserArgs = {
  objects: Array<UserInsertInput>;
  on_conflict?: Maybe<UserOnConflict>;
};

/** mutation root */
export type MutationRootInsertUserOneArgs = {
  object: UserInsertInput;
  on_conflict?: Maybe<UserOnConflict>;
};

/** mutation root */
export type MutationRootInsertVerifyCodeArgs = {
  objects: Array<VerifyCodeInsertInput>;
  on_conflict?: Maybe<VerifyCodeOnConflict>;
};

/** mutation root */
export type MutationRootInsertVerifyCodeOneArgs = {
  object: VerifyCodeInsertInput;
  on_conflict?: Maybe<VerifyCodeOnConflict>;
};

/** mutation root */
export type MutationRootSendVerifyCodeArgs = {
  input: SendVerifyCodeInput;
};

/** mutation root */
export type MutationRootSignInArgs = {
  credentials: SignInInput;
};

/** mutation root */
export type MutationRootSignUpArgs = {
  credentials: SignUpInput;
};

/** mutation root */
export type MutationRootSocialSignInArgs = {
  credentials: SocialSignInInput;
};

/** mutation root */
export type MutationRootUpdatePasswordArgs = {
  credentials: UpdatePasswordInput;
};

/** mutation root */
export type MutationRootUpdatePhoneNumberArgs = {
  credentials: UpdatePhoneNumberInput;
};

/** mutation root */
export type MutationRootUpdateKeyDataArgs = {
  _append?: Maybe<KeyDataAppendInput>;
  _delete_at_path?: Maybe<KeyDataDeleteAtPathInput>;
  _delete_elem?: Maybe<KeyDataDeleteElemInput>;
  _delete_key?: Maybe<KeyDataDeleteKeyInput>;
  _prepend?: Maybe<KeyDataPrependInput>;
  _set?: Maybe<KeyDataSetInput>;
  where: KeyDataBoolExp;
};

/** mutation root */
export type MutationRootUpdateKeyDataByPkArgs = {
  _append?: Maybe<KeyDataAppendInput>;
  _delete_at_path?: Maybe<KeyDataDeleteAtPathInput>;
  _delete_elem?: Maybe<KeyDataDeleteElemInput>;
  _delete_key?: Maybe<KeyDataDeleteKeyInput>;
  _prepend?: Maybe<KeyDataPrependInput>;
  _set?: Maybe<KeyDataSetInput>;
  pk_columns: KeyDataPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateOrderArgs = {
  _set?: Maybe<OrderSetInput>;
  where: OrderBoolExp;
};

/** mutation root */
export type MutationRootUpdateOrderStatusArgs = {
  _set?: Maybe<OrderStatusSetInput>;
  where: OrderStatusBoolExp;
};

/** mutation root */
export type MutationRootUpdateOrderStatusByPkArgs = {
  _set?: Maybe<OrderStatusSetInput>;
  pk_columns: OrderStatusPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateOrderByPkArgs = {
  _set?: Maybe<OrderSetInput>;
  pk_columns: OrderPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdatePaymentStatusArgs = {
  _set?: Maybe<PaymentStatusSetInput>;
  where: PaymentStatusBoolExp;
};

/** mutation root */
export type MutationRootUpdatePaymentStatusByPkArgs = {
  _set?: Maybe<PaymentStatusSetInput>;
  pk_columns: PaymentStatusPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateProductArgs = {
  _append?: Maybe<ProductAppendInput>;
  _delete_at_path?: Maybe<ProductDeleteAtPathInput>;
  _delete_elem?: Maybe<ProductDeleteElemInput>;
  _delete_key?: Maybe<ProductDeleteKeyInput>;
  _prepend?: Maybe<ProductPrependInput>;
  _set?: Maybe<ProductSetInput>;
  where: ProductBoolExp;
};

/** mutation root */
export type MutationRootUpdateProductCustomizedArgs = {
  _append?: Maybe<ProductCustomizedAppendInput>;
  _delete_at_path?: Maybe<ProductCustomizedDeleteAtPathInput>;
  _delete_elem?: Maybe<ProductCustomizedDeleteElemInput>;
  _delete_key?: Maybe<ProductCustomizedDeleteKeyInput>;
  _prepend?: Maybe<ProductCustomizedPrependInput>;
  _set?: Maybe<ProductCustomizedSetInput>;
  where: ProductCustomizedBoolExp;
};

/** mutation root */
export type MutationRootUpdateProductCustomizedByPkArgs = {
  _append?: Maybe<ProductCustomizedAppendInput>;
  _delete_at_path?: Maybe<ProductCustomizedDeleteAtPathInput>;
  _delete_elem?: Maybe<ProductCustomizedDeleteElemInput>;
  _delete_key?: Maybe<ProductCustomizedDeleteKeyInput>;
  _prepend?: Maybe<ProductCustomizedPrependInput>;
  _set?: Maybe<ProductCustomizedSetInput>;
  pk_columns: ProductCustomizedPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateProductByPkArgs = {
  _append?: Maybe<ProductAppendInput>;
  _delete_at_path?: Maybe<ProductDeleteAtPathInput>;
  _delete_elem?: Maybe<ProductDeleteElemInput>;
  _delete_key?: Maybe<ProductDeleteKeyInput>;
  _prepend?: Maybe<ProductPrependInput>;
  _set?: Maybe<ProductSetInput>;
  pk_columns: ProductPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateUserArgs = {
  _append?: Maybe<UserAppendInput>;
  _delete_at_path?: Maybe<UserDeleteAtPathInput>;
  _delete_elem?: Maybe<UserDeleteElemInput>;
  _delete_key?: Maybe<UserDeleteKeyInput>;
  _prepend?: Maybe<UserPrependInput>;
  _set?: Maybe<UserSetInput>;
  where: UserBoolExp;
};

/** mutation root */
export type MutationRootUpdateUserByPkArgs = {
  _append?: Maybe<UserAppendInput>;
  _delete_at_path?: Maybe<UserDeleteAtPathInput>;
  _delete_elem?: Maybe<UserDeleteElemInput>;
  _delete_key?: Maybe<UserDeleteKeyInput>;
  _prepend?: Maybe<UserPrependInput>;
  _set?: Maybe<UserSetInput>;
  pk_columns: UserPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateVerifyCodeArgs = {
  _set?: Maybe<VerifyCodeSetInput>;
  where: VerifyCodeBoolExp;
};

/** mutation root */
export type MutationRootUpdateVerifyCodeByPkArgs = {
  _set?: Maybe<VerifyCodeSetInput>;
  pk_columns: VerifyCodePkColumnsInput;
};

/** column ordering options */
export enum OrderBy {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** query root */
export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "KeyData" */
  KeyData: Array<KeyData>;
  /** fetch aggregated fields from the table: "KeyData" */
  KeyData_aggregate: KeyDataAggregate;
  /** fetch data from the table: "KeyData" using primary key columns */
  KeyData_by_pk?: Maybe<KeyData>;
  /** fetch data from the table: "Order" */
  Order: Array<Order>;
  /** fetch data from the table: "OrderStatus" */
  OrderStatus: Array<OrderStatus>;
  /** fetch aggregated fields from the table: "OrderStatus" */
  OrderStatus_aggregate: OrderStatusAggregate;
  /** fetch data from the table: "OrderStatus" using primary key columns */
  OrderStatus_by_pk?: Maybe<OrderStatus>;
  /** fetch aggregated fields from the table: "Order" */
  Order_aggregate: OrderAggregate;
  /** fetch data from the table: "Order" using primary key columns */
  Order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "PaymentStatus" */
  PaymentStatus: Array<PaymentStatus>;
  /** fetch aggregated fields from the table: "PaymentStatus" */
  PaymentStatus_aggregate: PaymentStatusAggregate;
  /** fetch data from the table: "PaymentStatus" using primary key columns */
  PaymentStatus_by_pk?: Maybe<PaymentStatus>;
  /** fetch data from the table: "Product" */
  Product: Array<Product>;
  /** fetch data from the table: "ProductCustomized" */
  ProductCustomized: Array<ProductCustomized>;
  /** fetch aggregated fields from the table: "ProductCustomized" */
  ProductCustomized_aggregate: ProductCustomizedAggregate;
  /** fetch data from the table: "ProductCustomized" using primary key columns */
  ProductCustomized_by_pk?: Maybe<ProductCustomized>;
  /** fetch aggregated fields from the table: "Product" */
  Product_aggregate: ProductAggregate;
  /** fetch data from the table: "Product" using primary key columns */
  Product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: UserAggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
  /** fetch data from the table: "VerifyCode" */
  VerifyCode: Array<VerifyCode>;
  /** fetch aggregated fields from the table: "VerifyCode" */
  VerifyCode_aggregate: VerifyCodeAggregate;
  /** fetch data from the table: "VerifyCode" using primary key columns */
  VerifyCode_by_pk?: Maybe<VerifyCode>;
};

/** query root */
export type QueryRootKeyDataArgs = {
  distinct_on?: Maybe<Array<KeyDataSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<KeyDataOrderBy>>;
  where?: Maybe<KeyDataBoolExp>;
};

/** query root */
export type QueryRootKeyDataAggregateArgs = {
  distinct_on?: Maybe<Array<KeyDataSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<KeyDataOrderBy>>;
  where?: Maybe<KeyDataBoolExp>;
};

/** query root */
export type QueryRootKeyDataByPkArgs = {
  key: Scalars['String'];
};

/** query root */
export type QueryRootOrderArgs = {
  distinct_on?: Maybe<Array<OrderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderOrderBy>>;
  where?: Maybe<OrderBoolExp>;
};

/** query root */
export type QueryRootOrderStatusArgs = {
  distinct_on?: Maybe<Array<OrderStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusOrderBy>>;
  where?: Maybe<OrderStatusBoolExp>;
};

/** query root */
export type QueryRootOrderStatusAggregateArgs = {
  distinct_on?: Maybe<Array<OrderStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusOrderBy>>;
  where?: Maybe<OrderStatusBoolExp>;
};

/** query root */
export type QueryRootOrderStatusByPkArgs = {
  value: Scalars['String'];
};

/** query root */
export type QueryRootOrderAggregateArgs = {
  distinct_on?: Maybe<Array<OrderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderOrderBy>>;
  where?: Maybe<OrderBoolExp>;
};

/** query root */
export type QueryRootOrderByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootPaymentStatusArgs = {
  distinct_on?: Maybe<Array<PaymentStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PaymentStatusOrderBy>>;
  where?: Maybe<PaymentStatusBoolExp>;
};

/** query root */
export type QueryRootPaymentStatusAggregateArgs = {
  distinct_on?: Maybe<Array<PaymentStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PaymentStatusOrderBy>>;
  where?: Maybe<PaymentStatusBoolExp>;
};

/** query root */
export type QueryRootPaymentStatusByPkArgs = {
  value: Scalars['String'];
};

/** query root */
export type QueryRootProductArgs = {
  distinct_on?: Maybe<Array<ProductSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductOrderBy>>;
  where?: Maybe<ProductBoolExp>;
};

/** query root */
export type QueryRootProductCustomizedArgs = {
  distinct_on?: Maybe<Array<ProductCustomizedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductCustomizedOrderBy>>;
  where?: Maybe<ProductCustomizedBoolExp>;
};

/** query root */
export type QueryRootProductCustomizedAggregateArgs = {
  distinct_on?: Maybe<Array<ProductCustomizedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductCustomizedOrderBy>>;
  where?: Maybe<ProductCustomizedBoolExp>;
};

/** query root */
export type QueryRootProductCustomizedByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootProductAggregateArgs = {
  distinct_on?: Maybe<Array<ProductSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductOrderBy>>;
  where?: Maybe<ProductBoolExp>;
};

/** query root */
export type QueryRootProductByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootUserArgs = {
  distinct_on?: Maybe<Array<UserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserOrderBy>>;
  where?: Maybe<UserBoolExp>;
};

/** query root */
export type QueryRootUserAggregateArgs = {
  distinct_on?: Maybe<Array<UserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserOrderBy>>;
  where?: Maybe<UserBoolExp>;
};

/** query root */
export type QueryRootUserByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootVerifyCodeArgs = {
  distinct_on?: Maybe<Array<VerifyCodeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VerifyCodeOrderBy>>;
  where?: Maybe<VerifyCodeBoolExp>;
};

/** query root */
export type QueryRootVerifyCodeAggregateArgs = {
  distinct_on?: Maybe<Array<VerifyCodeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VerifyCodeOrderBy>>;
  where?: Maybe<VerifyCodeBoolExp>;
};

/** query root */
export type QueryRootVerifyCodeByPkArgs = {
  id: Scalars['uuid'];
};

export type SendVerifyCodeInput = {
  sessionUserId?: Maybe<Scalars['String']>;
  template: VerifyCodeTemplate;
  verifyEmail?: Maybe<Scalars['String']>;
  verifyPhoneNumber?: Maybe<Scalars['String']>;
};

export type SendVerifyCodeOutput = {
  __typename?: 'sendVerifyCodeOutput';
  success: Scalars['Boolean'];
};

/** subscription root */
export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "KeyData" */
  KeyData: Array<KeyData>;
  /** fetch aggregated fields from the table: "KeyData" */
  KeyData_aggregate: KeyDataAggregate;
  /** fetch data from the table: "KeyData" using primary key columns */
  KeyData_by_pk?: Maybe<KeyData>;
  /** fetch data from the table: "Order" */
  Order: Array<Order>;
  /** fetch data from the table: "OrderStatus" */
  OrderStatus: Array<OrderStatus>;
  /** fetch aggregated fields from the table: "OrderStatus" */
  OrderStatus_aggregate: OrderStatusAggregate;
  /** fetch data from the table: "OrderStatus" using primary key columns */
  OrderStatus_by_pk?: Maybe<OrderStatus>;
  /** fetch aggregated fields from the table: "Order" */
  Order_aggregate: OrderAggregate;
  /** fetch data from the table: "Order" using primary key columns */
  Order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "PaymentStatus" */
  PaymentStatus: Array<PaymentStatus>;
  /** fetch aggregated fields from the table: "PaymentStatus" */
  PaymentStatus_aggregate: PaymentStatusAggregate;
  /** fetch data from the table: "PaymentStatus" using primary key columns */
  PaymentStatus_by_pk?: Maybe<PaymentStatus>;
  /** fetch data from the table: "Product" */
  Product: Array<Product>;
  /** fetch data from the table: "ProductCustomized" */
  ProductCustomized: Array<ProductCustomized>;
  /** fetch aggregated fields from the table: "ProductCustomized" */
  ProductCustomized_aggregate: ProductCustomizedAggregate;
  /** fetch data from the table: "ProductCustomized" using primary key columns */
  ProductCustomized_by_pk?: Maybe<ProductCustomized>;
  /** fetch aggregated fields from the table: "Product" */
  Product_aggregate: ProductAggregate;
  /** fetch data from the table: "Product" using primary key columns */
  Product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: UserAggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
  /** fetch data from the table: "VerifyCode" */
  VerifyCode: Array<VerifyCode>;
  /** fetch aggregated fields from the table: "VerifyCode" */
  VerifyCode_aggregate: VerifyCodeAggregate;
  /** fetch data from the table: "VerifyCode" using primary key columns */
  VerifyCode_by_pk?: Maybe<VerifyCode>;
};

/** subscription root */
export type SubscriptionRootKeyDataArgs = {
  distinct_on?: Maybe<Array<KeyDataSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<KeyDataOrderBy>>;
  where?: Maybe<KeyDataBoolExp>;
};

/** subscription root */
export type SubscriptionRootKeyDataAggregateArgs = {
  distinct_on?: Maybe<Array<KeyDataSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<KeyDataOrderBy>>;
  where?: Maybe<KeyDataBoolExp>;
};

/** subscription root */
export type SubscriptionRootKeyDataByPkArgs = {
  key: Scalars['String'];
};

/** subscription root */
export type SubscriptionRootOrderArgs = {
  distinct_on?: Maybe<Array<OrderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderOrderBy>>;
  where?: Maybe<OrderBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrderStatusArgs = {
  distinct_on?: Maybe<Array<OrderStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusOrderBy>>;
  where?: Maybe<OrderStatusBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrderStatusAggregateArgs = {
  distinct_on?: Maybe<Array<OrderStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusOrderBy>>;
  where?: Maybe<OrderStatusBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrderStatusByPkArgs = {
  value: Scalars['String'];
};

/** subscription root */
export type SubscriptionRootOrderAggregateArgs = {
  distinct_on?: Maybe<Array<OrderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderOrderBy>>;
  where?: Maybe<OrderBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrderByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootPaymentStatusArgs = {
  distinct_on?: Maybe<Array<PaymentStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PaymentStatusOrderBy>>;
  where?: Maybe<PaymentStatusBoolExp>;
};

/** subscription root */
export type SubscriptionRootPaymentStatusAggregateArgs = {
  distinct_on?: Maybe<Array<PaymentStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PaymentStatusOrderBy>>;
  where?: Maybe<PaymentStatusBoolExp>;
};

/** subscription root */
export type SubscriptionRootPaymentStatusByPkArgs = {
  value: Scalars['String'];
};

/** subscription root */
export type SubscriptionRootProductArgs = {
  distinct_on?: Maybe<Array<ProductSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductOrderBy>>;
  where?: Maybe<ProductBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductCustomizedArgs = {
  distinct_on?: Maybe<Array<ProductCustomizedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductCustomizedOrderBy>>;
  where?: Maybe<ProductCustomizedBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductCustomizedAggregateArgs = {
  distinct_on?: Maybe<Array<ProductCustomizedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductCustomizedOrderBy>>;
  where?: Maybe<ProductCustomizedBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductCustomizedByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootProductAggregateArgs = {
  distinct_on?: Maybe<Array<ProductSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductOrderBy>>;
  where?: Maybe<ProductBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootUserArgs = {
  distinct_on?: Maybe<Array<UserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserOrderBy>>;
  where?: Maybe<UserBoolExp>;
};

/** subscription root */
export type SubscriptionRootUserAggregateArgs = {
  distinct_on?: Maybe<Array<UserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserOrderBy>>;
  where?: Maybe<UserBoolExp>;
};

/** subscription root */
export type SubscriptionRootUserByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootVerifyCodeArgs = {
  distinct_on?: Maybe<Array<VerifyCodeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VerifyCodeOrderBy>>;
  where?: Maybe<VerifyCodeBoolExp>;
};

/** subscription root */
export type SubscriptionRootVerifyCodeAggregateArgs = {
  distinct_on?: Maybe<Array<VerifyCodeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<VerifyCodeOrderBy>>;
  where?: Maybe<VerifyCodeBoolExp>;
};

/** subscription root */
export type SubscriptionRootVerifyCodeByPkArgs = {
  id: Scalars['uuid'];
};

/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type UpdatePhoneNumberInput = {
  code?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  sessionUserId?: Maybe<Scalars['String']>;
};

export type UpdatePhoneNumberOutput = {
  __typename?: 'updatePhoneNumberOutput';
  success: Scalars['Boolean'];
};

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};
