import { Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import getRenders from '../utils/getRenders';
import { CheckGroupInput, CheckGroupInputProps } from './inputs/CheckboxInput';
import RadioInput, { RadioInputProps } from './inputs/RadioInput';
import { QuickForm, QuickFormProps } from './QuickForm';

const pageSizeOptions: RadioInputProps['options'] = [
  { value: 10, label: '10 items' },
  { value: 20, label: '20 items' },
  { value: 50, label: '50 items' },
  { value: 100, label: '100 items' },
];

const { formItems } = getRenders({
  formItems: {
    pageSize: {
      label: 'Page size',
      name: 'pageSize',
      children: <RadioInput options={pageSizeOptions} />,
    },
    columns: {
      label: 'Visible columns',
      name: 'columns',
    },
  },
});

export type PageListPreferencesProps = {
  values?: {
    pageSize?: number;
    columns?: Record<string, boolean>;
  };
  columnOptions: CheckGroupInputProps['options'];
  onFinish?: QuickFormProps['onFinish'];
  onValuesChange?: QuickFormProps['onValuesChange'];
  form?: QuickFormProps['form'];
};

export default function PageListPreferences(props: PageListPreferencesProps) {
  const { values, columnOptions, ...formProps } = props;
  return (
    <QuickForm layout="vertical" initialValues={values} {...formProps}>
      <Row>
        <Col>{formItems.pageSize()}</Col>
        <Col>
          {formItems.columns({
            children: <CheckGroupInput options={columnOptions} />,
          })}
        </Col>
      </Row>
    </QuickForm>
  );
}

export function usePageListPreferencesModal(props: PageListPreferencesProps) {
  const { onFinish, ...rest } = props;
  const [visible, setVisibleState] = useState(false);
  const [values, setValues] = useState(props.values);
  const [form] = useForm();

  const setVisible = (v: boolean, nextValues?: any) => {
    if (v && nextValues) {
      form.setFieldsValue(cloneDeep(nextValues));
    }
    return setVisibleState(v);
  };

  const content = useMemo(() => {
    return (
      <Modal
        title="Preferences"
        centered
        visible={visible}
        onOk={() => {
          onFinish?.(values);
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
      >
        <PageListPreferences
          {...rest}
          form={form}
          onValuesChange={(_, v) => setValues(v)}
        />
      </Modal>
    );
  }, [visible, props]);

  return [setVisible, content] as [typeof setVisible, typeof content];
}
