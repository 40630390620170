// https://github.com/simonbengtsson/jsPDF-AutoTable
// https://datatables.net/extensions/buttons/examples/html5/simple.html
// https://docs.sheetjs.com/docs/getting-started/example
// https://github.com/mozilla/pdf.js (Viewer)
import * as XLSX from 'xlsx';

export type ExportExcelOptions<T = any> = {
  rows: T[];
  headers?: string[];
  filename?: string; // to enable download filename
};

export function exportExcel(options: ExportExcelOptions) {
  const { rows, headers, filename = 'export.xlsx' } = options;

  /* generate worksheet and workbook */
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');

  /* fix headers */
  if (headers) {
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
  }

  /* calculate column width */
  // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
  // worksheet['!cols'] = [{ wch: max_width }];

  const xlsxFilename = filename.replace(/\.xlsx$/, ''); // flexible filename
  XLSX.writeFile(workbook, `${xlsxFilename}.xlsx`, { compression: true });
}
