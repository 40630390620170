import React, { useMemo } from 'react';
import { useRef } from 'react';
import {
  Button,
  ButtonProps,
  Input,
  InputRef,
  Space,
  Table,
  TableProps,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { SortOrder, TableAction } from 'antd/lib/table/interface';
import { ColSearchInput } from './ColSearchInput';
import { OrderBy } from '../../modules/apollo/types';
import { Link } from 'gatsby';

// export type ColumnSearch = Record<string, string>;

export type RowActionProps = ButtonProps & { key?: string };
export type TableRowActionProps<T = any> = (
  | RowActionProps
  | ((record: T) => RowActionProps)
)[];

export type TableListProps<T = any> = {
  onChange?: (data: TableListProps<T>['filters'], action: TableAction) => void;

  /**
   * Control table state
   */
  filters?: {
    criteria?: Record<string, unknown>;
    total?: number;
    offset?: number;
    limit?: number;
    orderBy?: Record<string, OrderBy>;
  };

  /**
   * Define row actions buttons
   */
  rowActions?: TableRowActionProps;
  onRowAction?: (rowAction: RowActionProps, record: T) => void;

  /**
   * Enable select rows
   * https://ant.design/components/table#rowselection
   */
  selected?: string[]; // selected row keys
  onSelected?: (selected: string[]) => void;
} & Pick<TableProps<T>, 'columns' | 'dataSource' | 'loading'>;

export function TableList<T extends object>({
  columns: columnsProp,
  onChange,
  dataSource,
  filters,
  loading = false,
  rowActions,
  onRowAction,
  selected,
  onSelected,
}: TableListProps<T>) {
  const input = useRef<InputRef | null>();
  const { limit, offset, total, criteria, orderBy } = {
    criteria: {},
    total: 0,
    limit: 10,
    offset: 0,
    orderBy: {},
    ...filters,
  };

  /**
   * Add column props base on table state
   */
  const getColumnSearchProps = (col: ColumnType<T>): ColumnType<T> => {
    const dataIndex = col.dataIndex as string;
    const sortOrderMap: Record<string, SortOrder> = {
      [OrderBy.Asc as string]: 'ascend',
      [OrderBy.Desc as string]: 'descend',
    };
    return {
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }) => (
      //   <ColSearchInput
      //     ref={(node) => {
      //       input.current = node;
      //     }}
      //     placeholder={`Search ${col.title}`}
      //     value={selectedKeys[0] as string}
      //     onChange={(value) => setSelectedKeys(value ? [value] : [])}
      //     onReset={clearFilters}
      //     onSubmit={() => confirm()}
      //     onFilter={() => confirm({ closeDropdown: false })}
      //   />
      // ),
      // filterIcon: () => (
      //   <SearchOutlined
      //     style={{ color: criteria[dataIndex] ? '#1890ff' : undefined }}
      //   />
      // ),
      // onFilterDropdownVisibleChange: (visible: boolean) => {
      //   if (visible) {
      //     setTimeout(() => input.current && input.current.select(), 100);
      //   }
      // },
      sorter: {
        multiple: 1,
      },
      sortOrder: orderBy[dataIndex] ? sortOrderMap[orderBy[dataIndex]] : null,
    };
  };

  const columns: ColumnsType<T> = (columnsProp || []).map((col) => {
    return {
      ...getColumnSearchProps(col),
      ...col,
    };
  });

  if (rowActions?.length) {
    columns.push({
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (text: any, record: T) => (
        <Space size="middle">
          {rowActions.map((rowAction, index) => {
            const btnProps =
              typeof rowAction === 'function' ? rowAction(record) : rowAction;
            const { href, target, ...rest } = btnProps;
            if (href) {
              if (target === '_blank') {
                return (
                  <Button
                    type="link"
                    size="small"
                    href={href}
                    target={target}
                    {...rest}
                  />
                );
              }
              return (
                <Link key={index} to={href}>
                  <Button size="small" type="primary" {...rest} />
                </Link>
              );
            }
            return (
              <Button
                key={index}
                size="small"
                type="primary"
                {...rest}
                onClick={() => {
                  onRowAction && onRowAction(btnProps, record);
                }}
              />
            );
          })}
        </Space>
      ),
    });
  }

  return (
    <Table<T>
      columns={columns}
      dataSource={dataSource}
      // normal record, keydata, enum // should optimized this
      rowKey={(record: any, index) =>
        record.id || record.key || record.value || index
      }
      loading={loading}
      scroll={{ x: true }}
      pagination={
        onChange
          ? {
              total,
              pageSize: limit,
              current: offset / limit + 1,
            }
          : false
      }
      onChange={(pagination, filters, sorter, { action }) => {
        // Map criteria, ex: filters.type = null or ['test'] => criteria.type = 'test';
        const criteria = Object.keys(filters).reduce((prev, key) => {
          if (filters[key]) {
            prev[key] = (filters[key] as any[])[0];
          }
          return prev;
        }, {} as Record<string, any>);

        // Map sort order
        const sorters = Array.isArray(sorter) ? sorter : [sorter];
        const orderBy = sorters.reduce<Record<string, OrderBy>>(
          (prev, { field, order }) => {
            if (field && order) {
              prev[field as string] = {
                ascend: OrderBy.Asc,
                descend: OrderBy.Desc,
              }[order];
            }
            return prev;
          },
          {}
        );

        // get limit, reset offset if filter changed
        const limit = pagination.pageSize || 10;
        const offset =
          action === 'filter' ? 0 : ((pagination.current || 1) - 1) * limit;

        // With map offset & limit
        onChange &&
          onChange(
            {
              offset,
              limit,
              criteria,
              orderBy,
            },
            action
          );
      }}
      rowSelection={
        onSelected && {
          type: 'checkbox',
          selectedRowKeys: selected,
          onChange: (selected) =>
            onSelected(selected.map((item) => item.toString())),
        }
      }
    />
  );
}
