import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import storage from '../utils/storage';

/**
 * Set/Get data with local storage sync
 * Notes: this only support one way sync after load local value: state value -> local value
 * there no local value -> emit state value
 */
export default function useLocalState<T extends Record<string, unknown>>(
  key: string
) {
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState<T | null>(null);

  // local store
  const local = useMemo(() => {
    setLoaded(false);
    return storage(key);
  }, [key]);

  // init - load value in local store
  useEffect(() => {
    local.get().then((v) => {
      setValue(v as T);
      setLoaded(true);
    });
  }, [local, setValue]);

  // on value change - set it to local store
  // omit the first load value
  useEffect(() => {
    if (!loaded) {
      return;
    }
    if (value) {
      local.set(value);
    } else {
      local.remove();
    }
  }, [local, value, loaded]);

  return [value, setValue, loaded] as [typeof value, typeof setValue, boolean];
}
