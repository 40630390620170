// Async for support many adapters
const Adapter = {
  getItem: (key: string) => Promise.resolve(localStorage.getItem(key)),
  setItem: (key: string, value: any) =>
    Promise.resolve(localStorage.setItem(key, value)),
  removeItem: (key: string) => Promise.resolve(localStorage.removeItem(key)),
};

/**
 * Set data to local storage
 * data = null to remove
 */
export async function set<T extends Record<string, unknown>>(
  key: string,
  data: T | null
) {
  if (!data) {
    await Adapter.removeItem(key);
    return;
  }
  const stringifiedData = JSON.stringify(data);
  return Adapter.setItem(key, stringifiedData);
}

/**
 * Get data from local storage
 */
export async function get<T extends Record<string, unknown>>(
  key: string
): Promise<T | null> {
  const data = await Adapter.getItem(key);
  try {
    return data ? JSON.parse(data) : null;
  } catch {
    return null;
  }
}

/**
 * Helper quick set, get local storage
 */
export default function storage<T extends Record<string, unknown>>(
  key: string
) {
  return {
    set: (data: T) => set<T>(key, data),
    get: () => get<T>(key),
    remove: () => set(key, null),
  };
}
