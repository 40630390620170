import React from 'react';
import { Alert, Button, Card, Col, Modal, Row, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, PageProps } from 'gatsby';
import { TableList, TableListProps } from '../../components/TableList';
import {
  getCommonRecordDisplay,
  getExportData,
  getTableColumns,
} from '../../modules/row-data/utils';
import Layout, { LayoutProps } from '../../components/Layout';
import createPrivatePage from '../../utils/createPrivatePage';
import {
  useTableDelete,
  useTableSearch,
  useTableUpdateMany,
} from '../../modules/row-data/queryHooks';
import { useAppContext } from '../../modules/app';
import { Helmet } from 'react-helmet';
import Text from 'antd/lib/typography/Text';
import { noop, pick, pickBy, startCase, valuesIn } from 'lodash';
import { SettingOutlined, SyncOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import config from '../../modules/dynamic-config';
import { usePageListPreferencesModal } from '../../components/PageListPreferences';
import useLocalState from '../../hooks/useLocalState';
import { OrderBy } from '../../modules/apollo/types';
import { useCallback } from 'react';
import { exportExcel } from '../../utils/exportDataHelpers';
import { getInputConfig } from '../../modules/row-data';
import VSpace from '../../components/VSpace';
import { client } from '../../modules/apollo';
import { usePromiseResult } from '../../hooks/usePromiseResult';

function PageList(props: PageProps) {
  const {
    params: { table: tableName },
  } = props;
  const resources = useAppContext().resources;
  if (!resources[tableName]) {
    console.log(resources);
    throw new Error(`Not found resource table ${tableName}`);
  }
  const tableResource = resources[tableName];
  const {
    colFields,
    spec: { permissions },
    dataSetting,
    docs,
  } = tableResource;
  const [variables, setVariables] = useState<any>();
  const { loading, data, refetch } = useTableSearch(tableName, {
    skip: !variables,
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const [deleteMutation] = useTableDelete(tableName);
  const [updateMany] = useTableUpdateMany(tableName);

  const [preferences, setPreferences, preferencesLoaded] = useLocalState<{
    pageSize: number;
    columns: Record<string, boolean>;
  }>(`${tableName}::list`);

  // available columns can toggle visible
  const columnOptions = useMemo(() => {
    return Object.entries(colFields || {}).map(([k, c]) => {
      return { value: k, label: c.col.title || startCase(k) };
    });
  }, [colFields]);

  // local preferences maybe null, currentPreferences get correct values
  const currentPreferences = useMemo(() => {
    return {
      pageSize: preferences?.pageSize || 10,
      columns: Object.fromEntries(
        Object.keys(colFields).map((k) => [
          k,
          preferences?.columns?.[k] ??
            !colFields[k].col.defaultHideColumn ??
            true,
        ])
      ),
    };
  }, [preferences, colFields]);

  // the columns will show on list
  const columns: TableListProps<any>['columns'] = useMemo(() => {
    const showKeys = Object.keys(currentPreferences.columns).filter(
      (k) => currentPreferences.columns[k]
    );
    const tableColumns = getTableColumns(tableResource);
    return tableColumns.filter((item) =>
      showKeys.includes(item.dataIndex as string)
    );
  }, [colFields, currentPreferences.columns]);

  // list props
  const records = data.records;
  const total = data.count || 0;
  const [togglePreferences, preferencesContent] = usePageListPreferencesModal({
    columnOptions,
    values: currentPreferences,
    onFinish: (v) => {
      setPreferences(v);
    },
  });

  // Init table
  const [filters, setFilters] = useState<TableListProps<any>['filters']>();
  const [selected, setSelected] = useState<string[]>([]);
  const [shouldSelectedAllPages, setSelectedAllPages] =
    useState<boolean>(false);
  const isSelectedAll = records?.length && selected.length === records.length;
  const isSelectedAllPages = isSelectedAll && shouldSelectedAllPages;

  // On table filters change, set the query variables
  useEffect(() => {
    if (!filters) {
      return; // still init
    }
    const { criteria = {}, ...rest } = filters || {};

    // Generate where conditions base on criteria
    // @DEV: can break into a common function
    const where = {
      _and: [
        // Text search conditions
        ...['name'].reduce<any>((prev, key) => {
          if (criteria[key]) {
            prev.push({
              [key]: { _ilike: `%${criteria[key]}%` },
            });
          }
          return prev;
        }, []),
      ],
    };

    setVariables({
      ...rest,
      where,
    });
  }, [filters]);

  useEffect(() => {
    if (!preferencesLoaded) {
      return;
    }

    // templ default order by updatedOn
    const orderBy: Record<string, OrderBy> = colFields.updatedOn
      ? {
          updatedOn: OrderBy.Desc,
        }
      : colFields.createdOn
      ? {
          createdOn: OrderBy.Desc,
        }
      : {};

    setFilters((prev) => ({
      ...prev,
      limit: currentPreferences.pageSize,
      orderBy,
    }));
  }, [currentPreferences.pageSize, preferencesLoaded]);

  // Reset selected on records changed
  useEffect(() => {
    if (records) {
      setSelected([]);
      setSelectedAllPages(false);
    }
  }, [records]);

  // Row actions base on permission
  const rowActions: TableListProps<any>['rowActions'] = useMemo(() => {
    const result: TableListProps<any>['rowActions'] = [];

    if (permissions.update) {
      result.push((record) => ({
        children: 'Edit',
        key: 'edit',
        href: `/${tableName}/edit/${record.id || record.key}`,
      }));
    }

    if (permissions.delete) {
      result.push({ children: 'Delete', key: 'delete', danger: true });
    }

    const hookResults = config.getRowActions(result, { table: tableName });
    return hookResults;
  }, [permissions, tableName]);

  // Bulk action on colFields that have permissions
  const bulkActionColFields = useMemo(() => {
    return Object.values(
      pickBy(tableResource.colFields, (item) => {
        return item.col.bulkEditable && item.field.permissions.update;
      })
    );
  }, [tableResource]);

  const triggerBulkEdit = useCallback(
    async (values: any, ids: string[]) => {
      Modal.confirm({
        title: `Confirm bulk edit selected records`,
        content: <Text code>{JSON.stringify(values)}</Text>,
        onOk: async () => {
          await updateMany({
            variables: {
              where: isSelectedAllPages
                ? variables.where
                : {
                    id: {
                      _in: ids,
                    },
                  },
              data: values,
            },
          });
        },
      });
    },
    [updateMany, isSelectedAllPages, variables]
  );

  const triggerRowAction: TableListProps<any>['onRowAction'] = (
    { key },
    record
  ) => {
    if (key === 'delete') {
      Modal.confirm({
        title: 'Confirm Delete',
        content: (
          <Space direction="vertical">
            <div>Confirm delete "{getCommonRecordDisplay(record)}"?</div>
            <div>
              <Text code>ID: {record.id}</Text>
            </div>
          </Space>
        ),
        onOk: async () => {
          await deleteMutation({
            variables: {
              pk: record.id,
            },
          });
          refetch && refetch();
        },
      });
    }
  };

  const layoutProps = useMemo<Partial<LayoutProps>>(() => {
    const title = startCase(dataSetting?.title || tableName);
    return {
      pageTitle: (
        <Space>
          <Text>{title}</Text>
          <Text type="secondary">({total})</Text>
        </Space>
      ),
      breadcrumb: [
        { title: 'Home', path: '/' },
        { title: title, path: `/${tableName}/list` },
        { title: 'List', path: '' },
      ],
    };
  }, [tableName, dataSetting?.title, total]);

  /**
   * Export data handler
   */
  const [exportData, { loading: exporting }] = usePromiseResult(
    useCallback(async () => {
      /**
       * Get all records by limit records per request
       */
      async function getAllRecords(
        offset: number = 0,
        prevItems: any[] = []
      ): Promise<any[]> {
        const limit = 100;
        const result = await client.query({
          query: docs.search,
          variables: {
            ...variables,
            offset,
            limit,
          },
          fetchPolicy: 'no-cache',
        });
        const items = result.data[tableName];
        const nextItems = [...prevItems, ...items];
        if (items.length < limit) {
          return nextItems;
        }
        return getAllRecords(offset + limit, nextItems);
      }
      const exportRecords = isSelectedAllPages
        ? await getAllRecords()
        : records.filter((record: any) => selected.includes(record.id));

      // Parse data for export xlsx
      const data = await getExportData(exportRecords, tableResource);
      exportExcel({ ...data, filename: `${tableName}` });
    }, [selected, isSelectedAllPages, tableName, docs, variables, records])
  );

  /**
   * Render row selection status if needed
   */
  function renderRowSelectionStatus() {
    // no need to show status if it not selected all
    if (!selected.length || !isSelectedAll) {
      return null;
    }
    const totalSelected = isSelectedAllPages ? total : selected.length;
    const shouldShowSelectedAllPages = total > totalSelected;
    return (
      <Alert
        type="info"
        banner
        message={
          <Space>
            {isSelectedAllPages && (
              <>
                <Text>All {totalSelected} records are selected.</Text>
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    setSelected([]);
                    setSelectedAllPages(false);
                  }}
                >
                  Clear selection
                </Button>
              </>
            )}
            {!isSelectedAllPages && (
              <>
                <Text>
                  All {totalSelected} records on this page are selected.
                </Text>
                {shouldShowSelectedAllPages && (
                  <Button
                    size="small"
                    type="link"
                    onClick={() => setSelectedAllPages(true)}
                  >
                    Select all {total} records in filters
                  </Button>
                )}
              </>
            )}
          </Space>
        }
      />
    );
  }

  return (
    <Layout {...layoutProps}>
      <Helmet>
        <title>{`${tableName} List`}</title>
        <style type="text/css">{`
            .ant-table-column-title {
              white-space: nowrap;
            }
            .card-table .ant-card-body {
              padding: 0;
            }
            .card-table .ant-pagination {
              margin: 16px;
            }
        `}</style>
      </Helmet>

      <Row style={{ marginBottom: 24 }} justify="space-between">
        <Col>
          <Space>
            <Button icon={<SyncOutlined />} onClick={() => refetch()} />
            <Button danger disabled>
              Delete
            </Button>
            <Button
              disabled={!selected.length}
              onClick={exportData}
              loading={exporting}
            >
              Export XLSX
            </Button>
            {permissions.create && (
              <Link to={`/${tableName}/create`}>
                <Button type="primary">Create</Button>
              </Link>
            )}
          </Space>
        </Col>
        <Col>
          <Space>
            <Search
              placeholder="input search text"
              onSearch={noop}
              style={{ width: 200 }}
            />
            <Button
              onClick={() => togglePreferences(true, currentPreferences)}
              icon={<SettingOutlined />}
            />
          </Space>
        </Col>
      </Row>

      {!!bulkActionColFields.length && (
        <Row style={{ marginBottom: 24 }}>
          <Col>
            <Space>
              <Text strong>Bulk Edit:</Text>
              {bulkActionColFields
                .map((item) => {
                  const inputConfig = getInputConfig(item.col.type);
                  return (
                    <div key={item.field.name}>
                      {inputConfig.renderBulkEdit?.(
                        {
                          onSubmit: (values) =>
                            triggerBulkEdit(values, selected),
                          selected,
                        },
                        item,
                        tableResource
                      )}
                    </div>
                  );
                })
                .filter((item) => !!item)}
            </Space>
          </Col>
        </Row>
      )}

      <VSpace size="large">
        {renderRowSelectionStatus()}
        <Card className="card-table">
          <TableList
            loading={loading || !preferencesLoaded}
            filters={{ ...filters, total }}
            columns={columns}
            rowActions={rowActions}
            dataSource={records}
            onChange={(nextFilters, action) => {
              if (
                action === 'paginate' &&
                nextFilters?.limit !== filters?.limit
              ) {
                setPreferences({
                  ...currentPreferences,
                  pageSize: nextFilters?.limit as number,
                });
                return;
              }
              setFilters(nextFilters);
            }}
            onRowAction={triggerRowAction}
            selected={selected}
            onSelected={setSelected}
          />
        </Card>
      </VSpace>
      {preferencesContent}
    </Layout>
  );
}

// export default createPrivatePage(PageList);
export default PageList;
